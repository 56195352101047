import GuestLayout from '@/Layouts/GuestLayout';
import React, { useState } from 'react';

export default function Entrance(props) {
    const { fiscal_year, fiscal_year_lists } = props;

    const [fiscalYearURL, setFiscalYearURL] = useState((fiscal_year_lists[fiscal_year] ?? [])[1] ?? '');
    const fiscalYearLists = [];
    Object.keys(fiscal_year_lists).forEach((year) => {
        fiscalYearLists.push([year, fiscal_year_lists[year][0], fiscal_year_lists[year][1]]);
    });

    return (
        <GuestLayout>
            <br /><br /><br /><br /><br /><br />
                <div className="row">
                    <div className="col-md-6 offset-3">
                        <div className="row">
                            <h1 className="mb-5 fs-2 text-center">予約年度選択</h1>
                            <p className='text-center fs-5 mb-5'>
                                予約対象年度を選択してください。
                            </p>
                        </div>

                        <div className="row">
                            <div className="col">
                                <select id="site_select" value={fiscalYearURL} className='form-select' onChange={(e) => setFiscalYearURL(e.target.value)}>
                                    {fiscalYearLists.map((values) => (
                                            <option key={values[0]} value={values[2]}>{values[1]}</option>
                                        ))}
                                </select>
                                </div>
                            <div className="col">
                                <a href={fiscalYearURL} className="btn btn-primary">予約サイトに移動する</a>
                            </div>
                        </div>
                    </div>
                </div>
        </GuestLayout>
    );
}
